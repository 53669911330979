#topBar {
  width: 100%;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.1);
  position: relative;
  z-index: 1;

  h1 {
    margin: auto 0 auto 25px;
    font-size: 1rem !important;
  }

  #bell {
    margin: auto 15px auto auto;
  }

  #profileAreaWrapper {
    border-left: 1px solid rgb(219, 219, 219);

    #profileArea {
      margin: auto 50px auto 15px;
      display: flex;
      height: 100%;

      & > div.nav-item.dropdown {
        display: flex;

        #nav-dropdown {
          margin: auto;
        }
      }
    }
  }
}
