.course {
  width: 300px;
  text-decoration: none;
  transition: transform 0.4s;
  color: black;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;

  .course-bg-image {
    height: 150px;
    width: 100%;
    background-color: #eceffa;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px 10px 0 0;
  }

  .course-title-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    span:first-child {
      margin: 0;
      font-size: larger;
    }

    span:last-child {
      font-size: smaller;
      color: rgb(37, 37, 37);
      position: relative;
      top: 5px;
    }
  }

  .course-metadata-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 10px 10px;

    span {
      font-size: smaller;
      color: rgb(37, 37, 37);
      position: relative;
      top: 5px;
    }
  }

  .progress-bar-wrapper {
    padding: 0px 10px 20px 10px;

    .progress-title-wrapper {
      display: flex;
      justify-content: space-between;
      font-size: smaller;
      margin-bottom: 5px;
    }
  }

  &:hover {
    // transform: scale(1.02);
  }
}


@media (max-width: 1200px) {
  .course {
    width: 250px;
  }
}