#loginPage {
  height: 100vh;
  width: 100vw;

  .row {
    height: 100%;
    width: 100%;
    margin: 0;

    .loginColumn {
      background-color: white;
      display: grid;

      .loginColumnWrapper {
        margin: auto;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        form {
          label {
            margin-top: 15px;
            margin-bottom: 5px;
          }

          #forgotPasswordLink {
            display: block;
            margin: 15px 0 30px 0;
          }
        }
      }
    }

    .rightColumn {
      background-color: rgb(208, 208, 208);
      background-image: url(../../assets/loginBackground.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
