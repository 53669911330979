#AddStudentsPage {
  main {
    overflow-y: scroll;
    height: calc(100vh - 50px);

    .white-box {
      background-color: white;
      padding: 25px;
      border-radius: 10px;

      .Button {
        margin-bottom: 30px;
      }

      #review-students-header-wrapper {
        display: flex;
        justify-content: space-between;

        #review-students-button-wrapper {
          display: flex;

          .Button {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
