.assignment-card {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  outline: 1px solid rgb(241, 241, 241);
  padding: 10px;
  margin-bottom: 20px;
  text-decoration: none;
  color: black;

  p {
    margin: 0px;
  }

  span {
    font-size: 0.7rem;
  }
}

@media (max-width: 1200px) {
  .assignment-card {
    font-size: smaller;
  }
}