#CountdownTimer {
    .expired-notice {
        text-align: center;
        padding: 2rem;
        border: 1px solid #ebebeb;
        border-radius: 0.25rem;
        margin: 0.5rem;
    }
    
    .expired-notice>span {
        font-size: 2.5rem;
        font-weight: bold;
        color: red;
    }
    
    .expired-notice>p {
        font-size: 1.5rem;
    }
    
    .show-counter {
        padding: 0.5rem;
    }
    
    .show-counter .countdown-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
        padding: 0.5rem;
        border-radius: 0.25rem;
        text-decoration: none;
        color: #000;
    }
    
    .show-counter .countdown {
        line-height: 1.25rem;
        padding: 0 0.25rem;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    
    .show-counter .countdown.danger {
        color: #ff0000;
    }
    
    .show-counter .countdown>p {
        margin: 0;
    }
    
    .show-counter .countdown>span {
        text-transform: uppercase;
        font-size: 0.75rem;
        line-height: 1rem;
    }
}


