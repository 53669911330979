#assignment-sidebar {
  min-width: 400px;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 15px;
  max-height: calc(100vh - 50px - 50px);

  h3 {
    font-size: 1.25rem;
  }

  .past-submissions-wrapper,
  .upcoming-submissions-wrapper {
    height: 50%;

    .submissions-internal-wrapper {
      max-height: calc(100% - 32px); // 32px is the height of the title
      overflow-y: scroll;
      padding: 1px;
    }
  }
}

@media (max-width: 1440px) {
  #assignment-sidebar {
    min-width: 300px;
  }
}

@media (max-width: 1200px) {
  #assignment-sidebar {
    padding: 20px 10px;
    min-width: 180px;
  }
}
