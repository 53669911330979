#CompleteSignUpPage {
    display: grid;
    height: 100%;
    margin-top: 70px;

    .site-container {
        width: 100%;

        // TODO: componentise this gradient background and the white box inside it so it becomes a component
        // than can take child components to embed inside it. This component is currently used for both the
        // sign up page and the complete sign up page.
        .order-summary-wrapper {
            background: rgb(223, 243, 255);
            background: linear-gradient(45deg,
                    rgba(223, 243, 255, 1) 0%,
                    rgba(244, 228, 242, 1) 100%);
            padding: 80px;
            border-radius: 10px;
    
            .order-summary-inner {
                background-color: white;
                border-radius: 10px;
    
                .big-logo-wrapper {
                    display: grid;
                    margin-bottom: 30px;
    
                    .big-logo {
                        height: 30px;
                        margin: auto;
                        background-color: #f2f3f5;
                        padding: 20px;
                        box-sizing: content-box;
                        border-radius: 0 0 10px 10px;
                    }
                }
    
                .inner-wrapper {
                    width: 300px;
                    margin: auto;
                    padding-top: 3rem;
                    padding-bottom: 6rem;
        
                    h1 {
                        font-size: xx-large;
                        text-align: center;
                    }
        
                    form {
                        input {
                            padding: 5px;
                            margin-bottom: 15px;
                            width: 100%;

                            &:last-of-type {
                                margin-bottom: 20px;
                            }
                        }
        
                        .Button {
                            button {
                                width: 100%;
                            }
                        }
                    }
        
                }
            }
        }
    }
}