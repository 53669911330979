#StudentsListPage {
  main {
    overflow-y: scroll;
    height: calc(100vh - 50px);
  }

  .table-top-bar {
    background-color: white;
    padding: 15px 25px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;

    h2 {
      margin-top: auto;
      margin-bottom: auto;
    }

    .buttons-wrapper {
      display: flex;
      gap: 15px;

      .Button {
        margin: auto;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: smaller;

    .table-titles-wrapper {
      padding: 20px 10px;
      background-color: #f4f4f4;
      display: contents;
      color: rgb(111, 111, 111);

      tr {
        background-color: inherit;

        th {
          padding: 20px;
        }
      }
    }

    tr {
      background-color: white;
      text-align: left;
      border-bottom: 1px solid #f4f4f4;

      td {
        padding: 16px 25px;

        p {
          margin-top: 0;
          margin-bottom: 5px;
        }

        button {
          background-color: white;
          border: none;
          outline: 1px solid #316aff;
          padding: 10px;
          border-radius: 10px;
          color: #316aff;
          float: right;

          &:nth-of-type(2) {
            margin-right: 15px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }
}
