$color_primary: #316aff;
$color_secondary: #091228;
$color_tertiary: #262f45;
$color_fourth: #a3aab9;
$color_light_grey: #f3f3f3;
$newsletter_submit_button_width: 180px;
$landing_section_height_desktop: 100%;

#HomePage {
  .landing-section {
    overflow-x: hidden;

    // height: 80vh;
    // background-image: url(../../assets/homepage/Header.png);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;

    // display: block;
    position: absolute;
    height: $landing_section_height_desktop;
    width: 100%;
    top: 0;
    left: 0;

    .landing-section-background {
      position: absolute;

      display: flex;
      height: 100%;
      width: 100%;

      .landing-section-left {
        width: 55%;
        background-color: rgb(244, 245, 255);
      }

      .landing-section-image {
        width: 45%;
        background-image: url(../../assets/homepage/homepage.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .landing-section-overlay {
      // z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;

      .site-container {
        height: 100%;
        display: grid;

        div {
          margin-top: auto;
          margin-bottom: auto;
          max-width: 56%;
          padding-right: 15px;

          h1 {
            font-weight: bolder;
            font-size: 2.5rem;
          }

          .Button {
            position: relative;
            top: 20px;

            a {
              padding: 15px 30px;
              font-weight: bold;
            }
          }
        }
      }

      #mathsCardImage {
        width: 300px;
        height: 300px;
        position: absolute;
        right: 50px;
        bottom: 50px;
        background-image: url(../../assets/homepage/mathsCard.png);
      }

      #gradesImage {
        width: 230px;
        height: 115px;
        position: absolute;
        right: 35%;
        top: 200px;
        background-image: url(../../assets/homepage/assignmentScores.png);
      }

      #logoImage {
        width: 150px;
        height: 150px;
        position: absolute;
        right: 100px;
        top: 100px;
        background-image: url(../../assets/homepage/Logo.png);
      }
    }
  }

  #other-sections {
    position: absolute;
    top: $landing_section_height_desktop;
    width: 100%;

    section {
      width: 100%;
    }

    #supported-by {
      padding: 100px 0;

      .supported-by-wrapper {
        position: relative;
        width: fit-content;
        margin: auto;

        h2 {
          font-weight: bold;
          border: 1px solid $color_light_grey;
          border-bottom: none;
          width: fit-content;
          margin: auto;
          padding: 20px 50px;
          border-radius: 10px 10px 0 0;
        }

        img {
          position: absolute;
          top: -20px;
          right: -20px;
        }
      }

      .supported-by-logos {
        width: 100%;
        background-color: rgb(244, 245, 255);
        padding: 0 3rem;

        .site-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          gap: 100px;

          img {
            width: 100%;
            height: auto;
            margin: auto;
          }
        }

      }
    }

    #for-students,
    #for-schools {
      // Ensures that scrolling to these anchor ids has an offset of 75px so the titles
      // don't hide beneat the logo. See this link:
      // https://stackoverflow.com/a/24298427/13471035
      // MSTQ-14
      &::before {
        content: '';
        display: block;
        height:      75px;
        margin-top: -75px;
        visibility: hidden;
      }

      .site-container {
        .for-students-or-schools-inner-wrapper {
          display: flex;

          .for-students-or-schools-details {
            width: 40%;

            h2 {
              font-weight: bolder;
              margin-bottom: 2rem;
            }

            ul {
              list-style: none;
              font-size: 1.2rem;
              padding-left: 0;

              li {
                margin-bottom: 1.5rem;
              }
            }
          }

          .for-students-or-schools-image-wrapper {
            width: 60%;

            .gradient-background {
              background: rgb(244, 228, 242);
              background: linear-gradient(45deg,
                  rgba(244, 228, 242, 1) 0%,
                  rgba(223, 243, 255, 1) 100%);
              width: 100%;
              height: 100%;
              border-radius: 20px;
              display: grid;
              padding: 30px 0;
              -webkit-box-shadow: 0px 0px 30px 0px #e4e4e4;
              box-shadow: 0px 0px 30px 0px #e4e4e4;

              img {
                width: 100%;
                border-radius: 20px;
                margin-top: auto;
                margin-bottom: auto;
                position: relative;
                left: -30px;
                border: 1px solid rgb(232, 232, 232);
              }
            }
          }
        }
      }
    }

    #for-students {
      .site-container {
        .for-students-or-schools-inner-wrapper {
          .for-students-or-schools-image-wrapper {
            padding-left: 100px;
          }
        }
      }
    }

    #for-schools {
      .site-container {
        .for-students-or-schools-inner-wrapper {
          .for-students-or-schools-image-wrapper {
            padding-right: 100px;

            .gradient-background {
              background: linear-gradient(45deg,
                  rgba(223, 243, 255, 1) 0%,
                  rgba(244, 228, 242, 1) 100%);

              img {
                left: calc(30px);
              }
            }
          }
        }
      }
    }

    #our-vision {
      background-image: url(../../assets/homepage/ourVisionBg.svg);
      background-position: top;
      padding: 50px 0;

      .site-container {
        h2 {
          font-weight: bolder;
          margin-bottom: 1.5rem;
          text-align: center;
        }

        &>p {
          width: 60%;
          margin: auto auto 40px auto;
          text-align: center;
        }

        .vision-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;

          .vision-card {
            width: 85%;
            margin: auto;
            padding: 10px;

            p {
              background-color: white;
              width: 100%;
              padding: 20px;
              border-radius: 10px;
              box-shadow: 0px 0px 30px 0px #e7e7e7;
            }
          }
        }

        #dashboard-image-showcase-wrapper {
          display: grid;
          margin: 60px 0 30px 0;

          #dashboard-image-showcase {
            width: 80%;
            margin: auto;
            border-radius: 20px;
            box-shadow: 0px 0px 30px 0px #e7e7e7;
          }
        }
      }
    }

    #pricing {
      padding: 70px 0;

      .site-container {
        h2 {
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
        }

        &>p {
          width: 60%;
          text-align: center;
          margin: auto auto 50px auto;
        }

        .pricing-cards-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 30px;

          .pricing-card {
            width: 100%;
            padding: 50px;
            border-radius: 20px;
            background: linear-gradient(45deg,
                rgba(223, 243, 255, 1) 0%,
                rgba(244, 228, 242, 1) 100%);
            display: flex;

            #forStudentsImg,
            #forSchoolsImg {
              width: 40%;
            }

            .circles {
              position: absolute;
              margin-left: -150px;
              margin-top: -175px;
              z-index: -1;
            }

            .pricing-info {
              h3 {
                font-weight: bold;
              }

              .price-per-year {
                .price {
                  font-size: 2.2rem;
                  color: $color_primary;
                  font-weight: bolder;
                }

                .per-unit-time {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
    }

    #newsletter {
      background-image: url(../../assets/homepage/newsletter-background.png);
      background-position: top;
      padding: 70px 0;

      .site-container {
        h2 {
          font-weight: bolder;
          margin-bottom: 1.5rem;
          text-align: center;
        }

        p {
          width: 70%;
          margin: auto auto 30px auto;
          text-align: center;
        }

        form {
          width: calc(100% - $newsletter_submit_button_width);
          margin: auto;
          display: flex;
          box-shadow: 0px 0px 30px 0px #e7e7e7;

          input {
            width: 80%;
            padding: 10px 30px;
            border: none;
            border-radius: 10px 0 0 10px;
            font-size: 1.3rem;

            &:focus {
              outline: none;
            }
          }

          .email-submit-button-wrapper {
            width: $newsletter_submit_button_width;
            background-color: white;
            padding: 15px;
            border-radius: 0 10px 10px 0;

            .Button {
              button {
                width: 100%;
                font-weight: bold;
                padding: 15px 0;
                font-size: larger;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  #HomePage {
    #other-sections {
      #pricing {
        .site-container {
          .pricing-cards-wrapper {
            .pricing-card {

              #forStudentsImg,
              #forSchoolsImg {
                position: relative;
                bottom: 40px;
              }
            }
          }
        }
      }
    }


  }
}



@media (max-width: 1440px) {
  #HomePage {
    .landing-section {
      .landing-section-background {
        .landing-section-left {
          width: 60%;
        }

        .landing-section-image {
          width: 40%;
        }
      }

      .landing-section-overlay {
        #gradesImage {
          width: 200px;
          height: 100px;
          right: 30%;
        }

        #logoImage {
          width: 130px;
          height: 130px;
        }

        #mathsCardImage {
          width: 270px;
          height: 270px;
        }

        .site-container {
          div {
            .Button {
              top: 10px;
              a {
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }

    #other-sections {

      #supported-by {
        .supported-by-logos {
          // width: 100%;
          // background-color: rgb(244, 245, 255);
          // padding: 0 3rem;

          .site-container {
            // display: grid;
            // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 80px;

            // img {
            //   width: 100%;
            //   height: fit-content;
            //   margin: auto;
            // }
          }
        }
      }



      #pricing {
        .site-container {
          .pricing-cards-wrapper {
            .pricing-card {

              #forStudentsImg,
              #forSchoolsImg {
                position: unset;
                bottom: 0px;
                display: none;
              }
            }
          }
        }
      }
    }
  }
}


@media (max-width: 1024px) {
  #HomePage {
    .landing-section {
      .landing-section-background {
        .landing-section-left {
          width: 65%;
        }

        .landing-section-image {
          width: 35%;
        }
      }

      .landing-section-overlay {
        #gradesImage {
          width: 180px;
          height: 90px;
          right: 25%;
        }

        #logoImage {
          width: 110px;
          height: 110px;
        }

        #mathsCardImage {
          width: 250px;
          height: 250px;
        }
      }
    }

    #other-sections {
      #supported-by {
        .supported-by-logos {
          // width: 100%;
          // background-color: rgb(244, 245, 255);
          // padding: 0 3rem;

          .site-container {
            // display: grid;
            // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 50px;

            // img {
            //   width: 100%;
            //   height: fit-content;
            //   margin: auto;
            // }
          }
        }
      }

      #newsletter {
        .site-container {
          form {
            width: 100%;

            input {
              width: calc(100% - 150px);
              font-size: 1rem;
            }

            .email-submit-button-wrapper {
              width: 150px;

              .Button {
                button {
                  width: 100%;
                  font-weight: bold;
                  padding: 10px 0;
                  font-size: medium;
                }
              }
            }
          }
        }
      }

      #for-students,
      #for-schools {
        .site-container {
          .for-students-or-schools-inner-wrapper {
            // display: flex;

            .for-students-or-schools-details {
              //   width: 40%;

              //   h2 {
              //     font-weight: bolder;
              //     margin-bottom: 2rem;
              //   }

              ul {
                // list-style: none;
                font-size: 1rem;
                // padding-left: 0;

                // li {
                //   margin-bottom: 1.5rem;
                // }
              }
            }

            .for-students-or-schools-image-wrapper {
              // width: 60%;

              .gradient-background {
                // background: rgb(244, 228, 242);
                // background: linear-gradient(45deg,
                //     rgba(244, 228, 242, 1) 0%,
                //     rgba(223, 243, 255, 1) 100%);
                // width: 100%;
                height: unset;
                // border-radius: 20px;
                // display: grid;
                // padding: 30px 0;
                // -webkit-box-shadow: 0px 0px 30px 0px #e4e4e4;
                // box-shadow: 0px 0px 30px 0px #e4e4e4;

                // img {
                //   width: 100%;
                //   border-radius: 20px;
                //   margin-top: auto;
                //   margin-bottom: auto;
                //   position: relative;
                //   left: -30px;
                //   border: 1px solid rgb(232, 232, 232);
                // }
              }
            }
          }
        }
      }

      #our-vision {
        // background-image: url(../../assets/homepage/ourVisionBg.svg);
        // background-position: top;
        // padding: 50px 0;

        .site-container {
          // h2 {
          //   font-weight: bolder;
          //   margin-bottom: 1.5rem;
          //   text-align: center;
          // }

          // &>p {
          //   width: 60%;
          //   margin: auto auto 40px auto;
          //   text-align: center;
          // }

          .vision-wrapper {
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // gap: 15px;

            .vision-card {
              width: 100%;
              // margin: auto;
              // padding: 10px;

              // p {
              //   background-color: white;
              //   width: 100%;
              //   padding: 20px;
              //   border-radius: 10px;
              //   box-shadow: 0px 0px 30px 0px #e7e7e7;
              // }
            }
          }

          // #dashboard-image-showcase-wrapper {
          //   display: grid;
          //   margin: 60px 0 30px 0;

          //   #dashboard-image-showcase {
          //     width: 80%;
          //     margin: auto;
          //     border-radius: 20px;
          //     box-shadow: 0px 0px 30px 0px #e7e7e7;
          //   }
          // }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #HomePage {

    .landing-section {
      .landing-section-background {
        .landing-section-left {
          width: 70%;
        }

        .landing-section-image {
          width: 30%;
        }
      }

      .landing-section-overlay {
        #gradesImage {
          width: 160px;
          height: 80px;
          right: 20%;
        }

        #logoImage {
          width: 90px;
          height: 90px;
        }

        #mathsCardImage {
          width: 230px;
          height: 230px;
        }
      }
    }

    #other-sections {
      #supported-by {
        padding: 50px 0;

        .supported-by-logos {
          // width: 100%;
          // background-color: rgb(244, 245, 255);
          // padding: 0 3rem;

          .site-container {
            // display: grid;
            // width: 80%;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;

            // img {
            //   width: 100%;
            //   height: fit-content;
            //   margin: auto;
            // }
          }
        }
      }

      #our-vision {
        .site-container {
          &>p {
            width: 80%;
          }

          .vision-wrapper {
            grid-template-columns: 1fr;
            gap: 0;

            .vision-card {
              text-align: center;
              width: 350px;
            }
          }
        }
      }

      #for-students,
      #for-schools {
        .site-container {
          .for-students-or-schools-inner-wrapper {
            display: grid;

            .for-students-or-schools-details {
              width: 70%;
              margin: auto;
              text-align: center;

              //   h2 {
              //     font-weight: bolder;
              //     margin-bottom: 2rem;
              //   }

              //   ul {
              //     list-style: none;
              //     font-size: 1.2rem;
              //     padding-left: 0;

              //     li {
              //       margin-bottom: 1.5rem;
              //     }
              //   }
            }

            .for-students-or-schools-image-wrapper {
              width: 70%;
              margin: auto;

              .gradient-background {
                // background: rgb(244, 228, 242);
                // background: linear-gradient(45deg,
                //     rgba(244, 228, 242, 1) 0%,
                //     rgba(223, 243, 255, 1) 100%);
                // width: 100%;
                // height: unset;
                // border-radius: 20px;
                // display: grid;
                // padding: 30px 0;
                // -webkit-box-shadow: 0px 0px 30px 0px #e4e4e4;
                // box-shadow: 0px 0px 30px 0px #e4e4e4;

                // img {
                //   width: 100%;
                //   border-radius: 20px;
                //   margin-top: auto;
                //   margin-bottom: auto;
                //   position: relative;
                //   left: -30px;
                //   border: 1px solid rgb(232, 232, 232);
                // }
              }
            }
          }
        }
      }


      #for-students {
        .site-container {
          .for-students-or-schools-inner-wrapper {
            .for-students-or-schools-image-wrapper {
              padding-left: 50px;
            }
          }
        }
      }

      #for-schools {
        .site-container {
          .for-students-or-schools-inner-wrapper {
            .for-students-or-schools-image-wrapper {
              order: 1;
              padding-right: 50px;

              // .gradient-background {
              //   background: linear-gradient(45deg,
              //       rgba(223, 243, 255, 1) 0%,
              //       rgba(244, 228, 242, 1) 100%);

              //   img {
              //     left: calc(30px);
              //   }
              // }
            }
          }
        }
      }

      #pricing {
        .site-container {
          .pricing-cards-wrapper {
            grid-template-columns: 1fr;
          }
        }
      }

      #newsletter {
        padding: 35px 0;
      }
    }
  }
}


@media (max-width: 576px) {

  #HomePage {
    .landing-section {
      .landing-section-background {
        .landing-section-left {
          width: 100%;
        }

        .landing-section-image {
          width: 0%;
        }
      }

      .landing-section-overlay {
        #gradesImage {
          top: 100px;
          right: 20px
        }

        #mathsCardImage {
          width: 180px;
          height: 180px;
          right: 20px;
          bottom: 20px;
        }

        #logoImage {
          display: none;
        }

        .site-container {
          div {
            max-width: 100%;
          }
        }
      }
    }

    #other-sections {
      #supported-by {
        .supported-by-logos {
          // width: 100%;
          // background-color: rgb(244, 245, 255);
          // padding: 0 3rem;

          .site-container {
            // display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            // img {
            //   width: 100%;
            //   height: fit-content;
            //   margin: auto;
            // }
          }
        }
      }


      #our-vision .site-container .vision-wrapper .vision-card {
        width: 250px;

        p {
          font-size: 0.8rem;
        }
      }

      #newsletter {
        .site-container {
          form {
            display: grid;
            box-shadow: none;

            input {
              width: 100%;
              border-radius: 10px;
              margin-bottom: 20px;
              font-size: 0.8rem;
            }

            .email-submit-button-wrapper {
              padding: 0;
              margin: auto;

              .Button {
                button {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }

}