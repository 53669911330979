#CreateAssignmentPage {
  main {
    overflow-y: scroll;
    height: calc(100vh - 50px);

    .white-box {
      background-color: white;
      padding: 25px;
      border-radius: 10px;
      font-size: 15px;

      .exam-details {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;

        .Input {
          padding: 15px 0;
          box-sizing: border-box;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            width: 50%;
          }

          &:nth-child(4),
          &:nth-child(5) {
            width: 50%;
          }

          &:nth-child(2) {
            padding-left: 15px;
            padding-right: 15px;
          }

          &:nth-child(4) {
            padding-right: 15px;
          }
        }
      }

      .topicQuestionAllocation {
        max-width: 800px;
        margin: 20px 0 40px 0;

        .titles {
          display: grid;
          grid-template-columns: 1fr 1fr;
          background-color: #f7f7f7;
          border-radius: 10px 10px 0 0;

          div {
            padding: 15px 0 15px 15px;
            text-align: left;
            // text-transform: uppercase;
          }
        }

        .allocations {
          border: 1px solid #f7f7f7;
          border-bottom: none;

          .allocation-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px solid #f7f7f7;

            .cell-item {
              padding: 10px 0 10px 15px;

              &:hover {
                background-color: #f7f7f7;
              }
            }

            .numberOfQuestionsSelectorWrapper {
              display: flex;
              // padding-right: 20px;

              .numberOfQuestionsSelector {
                padding: 0 10px;
                width: 100%;
                border: none;
                border-right: 16px solid transparent;

                &:focus {
                  outline: none;
                }

                &:disabled {
                  background-color: #fdffe8;

                  &:hover {
                    cursor: not-allowed;
                  }
                }
              }

              svg {
                margin: auto;
              }

              &:hover {
                background-color: #f7f7f7;
                cursor: pointer;

                .numberOfQuestionsSelector {
                  background-color: #f7f7f7;
                  cursor: pointer;
                }
              }
            }

            .topicName {
              border-right: 1px solid #f7f7f7;
            }

            .topicNameInput {
              // display: flex;
              width: 100%;
              // background-color: #f7f7f7;
              border: none;
              outline: none;
              border-radius: 5px;
              // padding: 5px 15px;
              border-right: 12px solid transparent;

              &:hover {
                background-color: #f7f7f7;
                cursor: pointer;
              }
            }
          }
        }
      }

      .buttons {
        margin-top: 50px;
        display: flex;
        justify-content: flex-end;

        .Button {
          margin-left: 10px;
        }
      }
    }
  }
}
