#ModulesListPage {
  // color: #b70000;
  // .row {
  //   overflow-y: scroll;
  // }
  main {
    height: calc(100vh - 50px);
    overflow-y: scroll;
    // background: linear-gradient(
    //   45deg,
    //   rgb(223, 243, 255) 0%,
    //   rgb(244, 228, 242) 100%
    // );
  }

  .table-top-bar {
    background-color: white;
    padding: 10px 25px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;

    h2 {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 1rem;
    }
    #numQuestionsSelected {
      font-size: 1rem;
      text-align: center;
      font-weight: bold;
    }
    #numQuestionsSelectedText {
      font-size: 0.7rem;
      text-align: center;
      color: grey;
      font-weight: bold;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: smaller;

    .table-titles-wrapper {
      padding: 20px 10px;
      background-color: #fafafa;
      display: contents;
      color: rgb(111, 111, 111);

      tr {
        background-color: inherit;

        th {
          padding: 12px;
          font-weight: normal;
        }
      }
    }

    tr {
      background-color: white;
      text-align: left;
      border-bottom: 1px solid #f4f4f4;

      &:hover {
        background-color: #f4f4f4;
        cursor: pointer;
        font-weight: bold;

        td {
          // font-size: 1rem;
          // transition: all .3s ease;
        }
      }

      &.selected {
        font-weight: bold;
        background-color: #f2f2f2;
        border-bottom: 1px solid #e8e8e8;
        // border-top: 1.4px solid #e0e0e0;
        box-shadow:
          rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
          rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      }

      input {
        cursor: pointer;
      }

      td {
        padding: 10px 25px;
        font-size: 0.9rem;
        padding: 9px 25px;
        // font-size: 0.8rem;

        @media (max-width: 1200px) {
          padding: 13px 20px;
        }

        p {
          margin-top: 0;
          margin-bottom: 5px;
        }

        .Button {
          float: right;
          &:nth-of-type(2) {
            margin-right: 15px;
          }

          @media (max-width: 1200px) {
            font-size: small;
          }
        }
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }

  #modules-page-filter-sidebar {
    padding-top: 25px;
    padding-bottom: 25px;

    .table-top-bar {
      padding-left: 0;
      padding-right: 0;
    }

    .checkbox-wrapper {
      margin-bottom: 7px;
    }

    input[type="checkbox"] {
      margin-right: 5px;
    }
  }

  .moduleRow {
    &:hover {
      .practiceModuleButton {
        visibility: visible !important;
      }
    }
  }
}
