#NavBar {
  $color_1: rgb(21, 21, 21);
  $color_primary: #316aff;
  $color_secondary: #050e22;
  $navbar_height_desktop: 70px;
  $navbar_height_tablet: 60px;
  $navbar_height_phone: 50px;

  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: $navbar_height_desktop;
    background: white;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    z-index: 99;

    .navbar {
      height: 100%;
      max-width: 1250px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      padding: 0 50px;

      .nav-links {
        line-height: $navbar_height_desktop;
        height: 100%;
      }

      .links {
        display: flex;

        li {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          list-style: none;
          padding: 0 20px;

          a {
            height: 100%;
            text-decoration: none;
            white-space: nowrap;
            color: $color_1;
            font-size: 15px;
            font-weight: 500;
          }

          .arrow {
            height: 100%;
            // width: 22px;
            line-height: $navbar_height_desktop;
            text-align: center;
            display: inline-block;
            color: $color_1;
            transition: all 0.3s ease;
          }

          .sub-menu {
            position: absolute;
            top: $navbar_height_desktop;
            left: 0;
            line-height: 40px;
            background: #3e8da8;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            border-radius: 0 0 4px 4px;
            display: none;
            z-index: 2;
            padding-left: 0;
            padding-right: 30px;
          }

          &:hover {
            .htmlCss-sub-menu {
              display: block;
            }

            .js-sub-menu {
              display: block;
            }
          }

          &.hideInDesktopView {
            display: none;
          }
        }
      }
    }
  }

  .navbar {
    .logo {
      a {
        font-size: 30px;
        color: $color_1;
        text-decoration: none;
        font-weight: 600;
      }
    }

    .links {
      li {
        .sub-menu {
          li {
            padding: 0 22px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }

          a {
            color: $color_1;
            font-size: 15px;
            font-weight: 500;
          }

          .sub-submenu-1-arrow {
            line-height: 40px;
          }

          .more-sub-menu {
            position: absolute;
            top: 0;
            left: 100%;
            border-radius: 0 4px 4px 4px;
            z-index: 1;
            display: none;
          }
        }
      }
    }

    .search-box {
      // position: relative;
      // height: 40px;
      // width: 40px;
      list-style: none;
      display: flex;
      margin-bottom: 0;
      padding-left: 0;

      li {
        margin: auto;
        padding: 0 20px;
        a {
          text-decoration: none;
          color: $color_1;
        }
      }

      i {
        position: absolute;
        height: 100%;
        width: 100%;
        line-height: 40px;
        text-align: center;
        font-size: 22px;
        color: $color_1;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease;
      }

      .input-box {
        position: absolute;
        right: calc(100% - 40px);
        top: 80px;
        height: 60px;
        width: 300px;
        background: #3e8da8;
        border-radius: 6px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s ease;
      }
    }

    .nav-links {
      .sidebar-logo {
        display: none;
      }
    }

    .bx-menu {
      display: none;
    }
  }

  .links {
    li {
      &:hover {
        .submenu-1-arrow {
          transform: rotate(180deg);
        }

        .submenu-2-arrow {
          transform: rotate(180deg);
        }
      }

      .sub-menu {
        .more {
          &:hover {
            .more-sub-menu {
              display: block;
            }
          }
        }
      }
    }
  }

  .navbar.showInput {
    .search-box {
      .input-box {
        top: 65px;
        opacity: 1;
        pointer-events: auto;
        background: #3e8da8;
      }
    }
  }

  .search-box {
    .input-box {
      &::before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        background: #3e8da8;
        right: 10px;
        top: -6px;
        transform: rotate(45deg);
      }

      input {
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 4px;
        transform: translate(-50%, -50%);
        height: 35px;
        width: 280px;
        outline: none;
        padding: 0 15px;
        font-size: 16px;
        border: none;
      }
    }
  }

  @media (max-width: 1440px) {
    nav {
      .navbar {
        // max-width: 100%;
        // padding: 0 25px;

        // .logo {
        //     a {
        //         font-size: 27px;
        //     }
        // }

        .links {
          li {
            padding: 0 15px;
            // white-space: nowrap;

            // a {
            //     font-size: 15px;
            // }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    nav {
      height: $navbar_height_tablet;
      .navbar {
        .logo {
          img {
            height: 25px;
          }
        }
        .nav-links {
          line-height: $navbar_height_tablet;

          .sidebar-logo {
            img {
              height: 20px;
            }
          }
        }
        .links {
          li {
            .arrow {
              line-height: $navbar_height_tablet;
            }

            .sub-menu {
              top: $navbar_height_tablet;
            }
          }
        }
      }
    }

    nav {
      .navbar {
        max-width: 100%;
        padding: 0 25px;

        .logo {
          a {
            font-size: 27px;
          }
        }

        .links {
          li {
            padding: 0 10px;
            white-space: nowrap;

            a {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 850px) {
    .navbar {
      .bx-menu {
        display: block;
        font-size: 20px;
        color: $color_1;
      }

      .search-box {
        display: none;
      }

      .nav-links {
        .sidebar-logo {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .links {
        visibility: visible;
        li {
          .sub-menu {
            .more-sub-menu {
              display: none;
              position: relative;
              left: 0;
              display: none;

              li {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }

            .more {
              span {
                display: flex;
                align-items: center;
              }
            }
          }

          &.hideInDesktopView {
            // TODO: get rid of !important here by ensuring css selector is specific enough.
            display: block !important;
          }
        }
      }

      .nav-links.show1 {
        .links {
          .htmlCss-sub-menu {
            display: block;
          }

          .submenu-1-arrow {
            transform: rotate(180deg);
          }
        }
      }

      .nav-links.show3 {
        .links {
          .js-sub-menu {
            display: block;
          }

          .submenu-2-arrow {
            transform: rotate(180deg);
          }
        }
      }

      .nav-links.show2 {
        .links {
          .more {
            .more-sub-menu {
              display: block;
            }
          }

          .sub-submenu-1-arrow {
            transform: rotate(90deg);
          }
        }
      }
    }

    nav {
      .navbar {
        .nav-links {
          position: fixed;
          top: 0;
          left: -100%;
          display: block;
          max-width: 270px;
          width: 100%;
          background: $color_secondary;
          line-height: 40px;
          padding: 20px;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          transition: all 0.5s ease;
          z-index: 1000;
        }

        .links {
          display: block;
          margin-top: 20px;

          li {
            a {
              color: white;
            }
            .arrow {
              line-height: 40px;
            }

            display: block;

            .sub-menu {
              position: relative;
              top: 0;
              box-shadow: none;
              display: none;

              li {
                border-bottom: none;
              }
            }

            &:hover {
              .htmlCss-sub-menu {
                display: none;
              }

              .js-sub-menu {
                display: none;
              }
            }
          }
        }
      }
    }

    .sidebar-logo {
      .logo-name {
        font-size: 25px;
        color: $color_1;
      }

      i {
        font-size: 25px;
        color: $color_1;
      }
    }

    .links {
      li {
        &:hover {
          .submenu-1-arrow {
            transform: rotate(0deg);
          }

          .submenu-2-arrow {
            transform: rotate(0deg);
          }
        }

        .sub-menu {
          .more {
            &:hover {
              .more-sub-menu {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    nav {
      height: $navbar_height_phone;
      .navbar {
        .bx-menu {
          font-size: 15px;
        }

        .logo {
          img {
            height: 20px;
          }
        }
        .nav-links {
          // line-height: $navbar_height_phone;

          .sidebar-logo {
            img {
              height: 20px;
            }

            // .bx-x {
            //     font-size: 15px;
            // }
          }
        }
        .links {
          li {
            .arrow {
              line-height: $navbar_height_phone;
            }

            .sub-menu {
              top: $navbar_height_phone;
            }
          }
        }
      }
    }
  }

  @media (max-width: 370px) {
    nav {
      .navbar {
        .nav-links {
          max-width: 100%;
        }
      }
    }
  }
}
