.Input {
  .inputLabel {
    display: block;
    background: #f7f7f7;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
  }

  input,
  select {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    border: none;
    // background-color: #f7f7f7;
    // border-radius: 10px;

    &:focus {
      outline: 1px solid lightgrey;
      outline: none;
    }
  }

  select {
    border-right: 16px solid transparent;
  }
}
