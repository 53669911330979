#SignUpPage {
  $color_primary: #316aff;
  $color_light_grey: #f3f3f3;
  margin-top: 70px;

  .order-summary-wrapper {
    background: rgb(223, 243, 255);
    background: linear-gradient(
      45deg,
      rgba(223, 243, 255, 1) 0%,
      rgba(244, 228, 242, 1) 100%
    );
    padding: 80px;
    border-radius: 10px;

    .order-summary-inner {
      background-color: white;
      border-radius: 10px;

      .big-logo-wrapper {
        display: grid;
        margin-bottom: 30px;

        .big-logo {
          height: 30px;
          margin: auto;
          background-color: #f2f3f5;
          padding: 20px;
          box-sizing: content-box;
          border-radius: 0 0 10px 10px;
        }
      }

      .inner-cols-wrapper {
        display: flex;
        border-top: 1px solid $color_light_grey;

        .order-summary-col {
          width: 66%;

          #form-wrapper {
            .order-summary-section-title {
              font-size: 1.5rem;
              padding-top: 20px;
              padding-bottom: 20px;
              font-weight: bold;
              border-bottom: 1px solid $color_light_grey;
              margin-bottom: 40px;
            }

            .required_at_least_13_years_old_checkbox {
              outline: 1px solid red;
            }

            .payment-plan-section {
              padding-top: 30px;
              padding-bottom: 30px;
              border-bottom: 1px solid $color_light_grey;
              display: flex;
              gap: 15px;

              h3 {
                font-weight: bold;
                color: $color_primary;
                font-size: 1.2rem;
                margin-bottom: 20px;
              }

              .payment-plan-option {
                width: 200px;
                text-align: center;
                border: 1px solid $color_light_grey;
                padding: 15px;
                border-radius: 10px;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);

                .checkbox-round {
                  width: 1.3em;
                  height: 1.3em;
                  background-color: white;
                  border-radius: 50%;
                  vertical-align: middle;
                  border: 3px solid $color_light_grey;
                  appearance: none;
                  -webkit-appearance: none;
                  outline: none;
                  cursor: pointer;
                  margin-top: 10px;
                  margin-bottom: 10px;

                  &:checked {
                    background-color: $color_primary;
                  }
                }

                label {
                  cursor: pointer;
                }

                &:hover {
                  cursor: pointer;
                }

                .payment-plan-price {
                  color: $color_primary;
                  font-size: 2rem;
                  margin-bottom: 0px;
                  font-weight: bold;
                }
              }
            }

            .user-details-section {
              padding-top: 30px;
              padding-bottom: 30px;
              // border-bottom: 1px solid $color_light_grey;

              input {
                width: 100%;
                padding: 10px;
                border: 1px solid $color_light_grey;
                border-radius: 5px;
                margin-bottom: 10px;

                &:focus {
                  outline: none;
                  box-shadow: 0 3px 10px rgba(95, 95, 95, 0.1);
                }
              }

              .user-details-name-wrapper {
                display: flex;
                justify-content: space-between;
                input {
                  width: 49%;
                }
              }
            }

            .billing-details-section {
              padding-top: 30px;
              padding-bottom: 70px;
            }
          }
        }

        .key-features-col {
          width: 34%;
          border-left: 1px solid $color_light_grey;

          .key-features-title {
            font-size: 1.5rem;
            padding: 20px 0;
            text-align: center;
            font-weight: bold;
            color: $color_primary;
            border-bottom: 1px solid $color_light_grey;
          }

          .key-features-list-wrapper {
            margin-top: 30px;

            ul {
              padding-left: 0;
              list-style: none;
              li {
                margin-bottom: 25px;
                font-weight: bold;
              }
            }
          }

          .stripe-payment-illustration {
            width: 80%;
            margin: auto auto 20px auto;
          }

          p {
            font-weight: bold;
          }

          .payment-methods-wrapper {
            display: flex;
            margin-bottom: 30px;
            img {
              margin-right: 5px;
            }
          }

          #powered-by-stripe {
            width: 100%;
            margin-bottom: 30px;
          }

          #please-contact-us {
            margin-bottom: 75px;
          }
        }

        .order-summary-col > #form-wrapper > * {
          padding-right: 60px;
          padding-left: 60px;
        }

        .key-features-col > * {
          padding-right: 40px;
          padding-left: 40px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  #SignUpPage .order-summary-wrapper {
    padding: 50px;
    .order-summary-inner .inner-cols-wrapper {
      .order-summary-col {
        width: 60%;
      }

      .key-features-col {
        .key-features-title {
          font-size: 1.2rem;
        }

        width: 40%;
        .key-features-list-wrapper ul li {
          font-weight: normal;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #SignUpPage .order-summary-wrapper {
    padding: 30px;
    .order-summary-inner .inner-cols-wrapper {
      display: grid;
      .order-summary-col {
        width: 100%;

        #form-wrapper .user-details-section .user-details-name-wrapper {
          display: grid;
          justify-content: unset;
          input {
            width: 100%;
          }
        }
      }

      .key-features-col {
        order: -1;
        display: grid;

        .key-features-title {
          font-size: 1.1rem;
          padding-left: 60px;
          padding-right: 60px;
          text-align: left;
        }

        width: 100%;
        .key-features-list-wrapper ul li {
          font-weight: normal;
          margin-bottom: 10px;
        }

        .stripe-payment-illustration {
          // max-width: 250px;
          // margin: auto;
          display: none;
        }

        #powered-by-stripe {
          // max-width: 400px;
          // margin: auto auto 20px auto;
          // margin: auto;
          display: none;
        }

        #please-contact-us {
          // display: none;
          margin-bottom: 10px;
          font-weight: normal;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  #SignUpPage .order-summary-wrapper {
    padding: 15px;
    .order-summary-inner .inner-cols-wrapper {
      .order-summary-col #form-wrapper {
        & > * {
          padding-right: 20px;
          padding-left: 20px;
        }
        .payment-plan-section .payment-plan-option {
          width: 150px;
          height: 150px;
          font-size: smaller;
          margin: auto;

          .checkbox-round {
            margin: 0;
          }

          .payment-plan-description {
            margin: 0;
          }
        }
      }

      .key-features-col {
        & > * {
          padding-left: 20px;
          padding-right: 20px;
        }
        .key-features-title {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}
