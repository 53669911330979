.courses-wrapper {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

#Dashboard {
  main {
    display: flex;
    justify-content: space-between;
    // background-color: #f7f7f7;

    #main-dashboard {
      // height set to 100vh - topBar height - padding top - padding bottom
      height: calc(100vh - 50px - 25px - 25px);
      width: 100%;
      overflow-y: scroll;
      margin-right: 30px;
      #welcome-back {
        display: flex;
        width: 100%;
        justify-content: space-between;
        background-color: #eceffa;
        // padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 40px;

        #message {
          padding: 40px;
          h3 {
            font-size: 1.5rem;
          }

          .Button {
            button {
              margin-top: 10px;
            }
          }
        }
      }

      #titleWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  }
}
